<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>위험발생이력</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<div class="panel-body" style="margin: 20px auto 0px 130px">
				<form @submit.prevent="search" @keyup.enter.prevent="search">
					<div class="row">
						<!-- 달력 -->
						<div class="col-md-4" style="width: 460px; margin-left: -20px">
							<div class="form-group">
								<select2 v-model="searchVM.dangerDtm" :options="options.dangerDtmOption"></select2>
								<input type="date" class="form-control" v-model="searchVM.dangerOccurDtmStat" />
								&nbsp;~&nbsp;
								<input type="date" class="form-control" v-model="searchVM.dangerOccurDtmEnd" :min="searchVM.dangerOccurDtmStat" :max="maxDay" />
							</div>
						</div>
						<!-- 현장 -->
						<div class="col-md-2" style="width: 265px; margin-left: -30px">
							<div class="form-group">
								<label style="width: 90px">{{ detailsFieldMap.searchSite }}</label>
								<select2 v-model="searchVM.siteId" :disabled="isDisabledSearchSite">
									<option value="">전체</option>
									<option v-for="item in options.siteOptions" :key="item.siteId" :value="item.siteId">
										{{ item.siteNm }}
									</option>
								</select2>
							</div>
						</div>
						<!-- CCTV -->
						<div class="col-md-2" style="width: 250px; margin-left: 10px">
							<div class="form-group">
								<label style="width: 73px">{{ detailsFieldMap.searchCctv }}</label>
								<select2 v-model="searchVM.cctvId">
									<option value="">전체</option>
									<option v-for="item in options.cctvOptions" :key="item.cctvId" :value="item.cctvId">
										{{ item.cctvNm }}
									</option>
								</select2>
							</div>
						</div>
					</div>
					<div class="row">
						<!-- 상태 -->
						<div class="col-md-2" style="width: 250px; margin-left: -48px">
							<div class="form-group">
								<label>{{ detailsFieldMap.searchVideoDangerActnDvsnCd }}</label>
								<select2 v-model="searchVM.videoDangerActnDvsnCd">
									<option value="">전체</option>
									<option v-for="item in options.videoDangerActnDvsnCdOption" :key="item.value" :value="item.value">
										{{ item.text }}
									</option>
								</select2>
							</div>
						</div>
						<!-- 대상물 -->
						<div class="col-md-3" style="width: 350px">
							<div class="form-group">
								<label>{{ detailsFieldMap.searchObject }}</label>
								<select2 v-model="searchVM.objectId">
									<option value="">전체</option>
									<option v-for="item in options.objectOptions" :key="item.objectId" :value="item.objectId">
										{{ item.objectNm }}
									</option>
								</select2>
							</div>
						</div>

						<!-- 조치사항 -->
						<div class="col-md-3" style="width: 320px; margin-left: 63px">
							<div class="form-group">
								<label>{{ detailsFieldMap.searchTarget }}</label>
								<select2 v-model="searchVM.targetId">
									<option value="">전체</option>
									<option v-for="item in options.targetOptions" :key="item.targetId" :value="item.targetId">
										{{ item.targetNm }}
									</option>
								</select2>
							</div>
						</div>

						<!-- 조회 버튼 -->
						<div class="mb-10 text-right" style="float: right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								조회
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Grid -->
		<div>
			<KendoGrid
				ref="grid"
				:auto-bind="false"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>
		</div>
		<div class="mt-10 mb-15 text-right"></div>
		<div id="detailFrames" class="form-horizontal form-validate-jquery" v-show="isEditMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<b>프레임 정보 ({{ detailsItem.cctvNmAndInstallPlace }})</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li><a @click="pannelHidden" data-action="collapse"></a></li>
						</ul>
					</div>
				</div>

				<div class="panel-body">
					<!-- <div class="border" style="width: 1020px; height: 530px; overflow-y: auto"> -->
					<div class="border" style="width: 1020px; height: auto; max-height: 800px; overflow-y: auto">
						<div style="display: inline-block; text-align: center" v-for="(item, index) in detailsItem.slicedDangerOccurFrameImgOutVOs" :key="index">
							<img
								:src="item.imgData"
								style="width: 200px; height: 112.5px; object-fit: contain; padding: 5px; display: block; cursor: pointer"
								@click="dangerOccurImgClicked(item, index)"
							/>
							<span>{{ item.imgSaveDtm }}</span>
						</div>
					</div>
					<div class="border" style="width: 358px; margin: 0 0 20px 20px">
						<div class="rhtBox" style="height: auto">
							<div style="display: inline-block; width: 100%"><h3>조치 대상</h3></div>
							<div
								class="row"
								style="width: 100%; min-height: 135px; margin: 0px"
								v-for="(targets, object) in dangerOccurObjectsAndTargets"
								:key="object"
							>
								<div style="font-size: 17px; margin-bottom: 10px; padding-left: 15px; margin-top: 15px; margin-left: 20px">{{ object }}</div>
								<li v-for="target in targets" :key="target" style="margin-left: 20px">
									{{ target }}
								</li>
							</div>
						</div>
						<div class="rhtBox">
							<div><h3>조치 이력</h3></div>
							<div v-for="(item, index) in detailsItem.inqDangerOccurActnOutVOs" :key="index">
								<div style="margin-top: 15px; margin-bottom: 15px; margin-left: 20px; padding-left: 15px">
									{{ item.actnDtm }} : {{ item.videoDangerActnDvsnCdNm }}
								</div>
							</div>
						</div>
						<div class="border-bottom" style="margin-bottom: 20px"></div>
						<div style="height: auto; display: flex; padding: 10px" v-if="curDangerDvsnCd != '04'">
							<div style="background: none; flex-grow: 2; margin: auto; text-align: center">
								<label style="margin: 0px auto">조치요청을 하시겠습니까?</label>
							</div>
							<input
								type="number"
								class="form-control"
								style="display: inline; width: 50px; text-align: center"
								v-model="detailsItem.takingActionMin"
							/>
							<div style="background: none; margin: auto; text-align: center; margin-left: 5px">
								<label style="margin: 0px auto">분</label>
							</div>
							<button class="btn bg-primary" style="margin-left: 10px" @click.stop="takingAction">조치중</button>
						</div>
						<div style="display: flex; margin: 10px 0px">
							<div v-if="detailsItem.videoDangerActnDvsnCd != '04'">
								<button
									class="btn bg-primary"
									style="flex: 1; margin: 0px 5px"
									@click.stop="misdetection"
									v-if="detailsItem.videoDangerActnDvsnCd != '05'"
								>
									오인식
								</button>
								<button
									class="btn bg-primary"
									style="flex: 1; margin: 0px 5px"
									@click.stop="deMisdetection"
									v-if="detailsItem.videoDangerActnDvsnCd == '05'"
								>
									오인식 취소
								</button>
							</div>
							<button class="btn bg-primary" style="flex: 1; margin: 0px 5px" @click.stop="popupExemplaryCase" v-if="menuGubun != '01'">
								모범사례
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div ref="popupImg" class="modal">
			<!-- Modal content -->
			<div class="modal-content">
				<span @click="popupImgClose" class="close">&times;</span>
				<div style="height: 30px"></div>
				<div class="row" style="display: flex; align-items: center; justify-content: center">
					<div class="col-md-3">
						<div
							style="
								flex: 1;
								overflow-y: auto;
								flex-direction: column;
								height: 720px;
								max-height: 720px;
								border-right: 1px solid #ddd;
								padding-right: 15px;
							"
							left
						>
							<div
								style="position: relative; overflow: hidden"
								v-for="(item, index) in detailsItem.inqDangerOccurFrameImgOutVOs"
								:key="index"
								:class="{ active: index == activeImage }"
								@click="selectPicture(index)"
								:tabindex="index"
								@keydown.up="priviousImage()"
								@keydown.left="priviousImage()"
								@keydown.down="nextImage()"
								@keydown.right="nextImage()"
								photo
							>
								<img :src="detailsItem.inqDangerOccurFrameImgOutVOs[index].imgData" />

								<div>
									<div style="position: absolute; width: 100%; height: 100%; top: 50%; left: 50%; transform: translate(-50%, -50%)"></div>
								</div>
							</div>
						</div>
					</div>
					<div align="center" class="col-md-9">
						<div align="center" right style="flex: 5 1 0%; height: inherit; justify-content: center">
							<img id="popupImg" :src="this.popupImg.imgData" style="width: 910px; max-height: 520px; object-fit: contain; display: block" />
						</div>
						<div align="center" right style="flex: 5 1 0%; margin-left: 15px; justify-content: center">
							<div class="row">
								<div class="mBox col-md-3">
									<div><h4>장소 및 시각</h4></div>
									<div class="border-bottom"></div>
									<div style="margin-top: 10px; margin-left: 20px">{{ detailsItem.cctvNmAndInstallPlace }}</div>
									<div style="margin-top: 10px; margin-left: 20px">{{ popupImg.imgSaveDtm }}</div>
								</div>
								<div class="mBox">
									<div><h4>조치 대상</h4></div>
									<div class="border-bottom"></div>
									<div style="display: flex; justify-content: center" :key="forRerendering">
										<div v-for="(element, index) in popupImg.objects" :key="index" style="display: inline-block; max-width: 50%; margin: 10px">
											<div style="font-size: 17px; font-weight: bold; text-align: center">
												{{ element.objectNm }}
											</div>
											<div style="font-size: 14px; text-align: center" v-for="(targetNm, i) in element.targetNms" :key="i">
												{{ targetNm }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 모범사례 -->
		<div ref="popupExemplaryCase" class="modal">
			<div class="modal-content">
				<div action="#" class="form-horizontal form-validate-jquery popupEx">
					<div>
						<div class="panel-heading">
							<input type="button" id="ex-caseBtn" value="사진" />
							<input type="button" id="lawBtn" value="관련법령" />
							<span @click="popupExemplaryCaseClose" class="close">&times;</span>
						</div>
						<div class="panel-body">
							<div class="panel panel-flat exCase row">
								<div class="col-md-9" align="center">
									<img :src="imageSrc" style="max-width: 100%; height: 600px; object-fit: contain" />
								</div>
								<div class="col-md-3">
									<!-- 파일 데이터를 보여줄 테이블 -->
									<div class="table-wrapper" ref="tableWrapperImage">
										<table class="table">
											<thead>
												<tr>
													<th>일자</th>
													<th>파일명</th>
												</tr>
											</thead>
											<tbody>
												<tr
													v-for="(row, index) in detailsItem.images"
													:key="index"
													@click="clickRowImage(index)"
													v-bind:class="{ active: activeImageForExemplaryCase == index }"
												>
													<td>{{ row.regDtm.slice(0, 10) }}</td>
													<td colspan="2">{{ row.imgOriNm }}</td>
												</tr>
												<tr empty>
													<td colspan="3">추가된 이미지가 없습니다</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div class="panel panel-flat law-panel" id="law-panel">
								<div class="panel-body">
									<div class="row" style="padding-left: 10px">
										<div class="col-md-9">
											<div>
												<label style="width: 70px; font-size: 18px">법조항</label>
												<input class="form-control" type="text" v-model="detailsItem.lawClss" :disabled="true" style="margin-bottom: 15px" />
											</div>
											<label style="font-size: 18px">법령</label>
											<textarea class="form-control" v-model="detailsItem.stte" disabled="true" style="margin-bottom: 15px"></textarea>
										</div>
										<div class="col-md-3">
											<!-- 법령 데이터를 보여줄 테이블 -->
											<div class="table-wrapper" ref="tableWrapperLaw">
												<table class="table">
													<thead>
														<tr algin="center">
															<th>일자</th>
															<th>법조항</th>
														</tr>
													</thead>
													<tbody>
														<tr
															v-for="(row, index) in detailsItem.laws"
															:key="index"
															:id="'laws_' + index"
															@click="clickRowLaw(index)"
															v-bind:class="{ active: activeLaw == index }"
														>
															<td>{{ row.regDtm.slice(0, 10) }}</td>
															<td colspan="2" align="left">{{ row.lawClss }}</td>
														</tr>
														<tr empty>
															<td colspan="3">추가된 법령이 없습니다</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import Select2 from '@/components/Select2';
import extend from 'extend';
import moment from 'moment';
import _ from 'lodash';
import apiIndex from '../../api/index';
const exemplaryCaseUrl = apiIndex.exemplaryCase;
const dangerOccurUrl = apiIndex.dangerOccur;

let axiosExtention;

export default {
	components: {
		KendoGrid,
		select2: Select2,
	},
	data: () => ({
		pageParam: null,
		apiUrl: {
			pageListApi: dangerOccurUrl.inqDangerOccur,
		},
		searchVM: {},
		// 상세 select options
		detailsOptions: {},
		// 검색 select options
		searchOptions: {},
		// 적용된 검색조건
		searchState: {},
		gridColumns: [
			{
				field: 'videoDangerActnDvsnCd',
				title: '상태',
				width: '10%',
				dataFormat: 'optionMap',
				align: 'center',
			},
			{ field: 'dangerOccurDtm', title: '발생시간', align: 'center' },
			{ field: 'dangerLiftDtm', title: '위험 해제 시간', align: 'center' },
			{ field: 'siteNm', title: '현장명', width: '15%' },
			{ field: 'cctvNmAndInstallPlace', title: 'CCTV', width: '15%' },
			{ field: 'obejctNmAndDangerZoneNm', title: '대상물' },
			{ field: 'targetNm', title: '조치사항' },
			{ field: 'actnReasonCont', title: '조치사유내용' },
		],
		detailsFieldMap: {
			searchSite: '현장',
			searchCctv: 'CCTV명',
			searchVideoDangerActnDvsnCd: '상태',
			searchObject: '대상물명',
			searchTarget: '조치사항명',
		},
		//상세편집 원본
		detailsItemOriginal: {},
		//상세편집 수정본
		detailsItem: {},
		popupImg: {},
		options: {
			siteOptions: [],
			cctvOptions: [],
			objectOptions: [],
			targetOptions: [],
			videoDangerActnDvsnCdOption: [],
			dangerDtmOption: [],
		},
		isDisabledSearchSite: true,
		activeImage: 0,
		isImagePoppedUp: false,
		dangerOccurObjectsAndTargets: {},
		dangerZoneAtSequenceMap: {},
		sequenceMapForDetail: {},
		outFramesDetailData: {},
		imageSrc: '/images/noimage.png',
		activeImageForExemplaryCase: 0,
		activeLaw: 0,
		menuGubun: '',
		curDangerDvsnCd: null,
		//dangerOccurDtmEnd의 지정 가능한 최대 일자(dangerOccurDtmStat 기준으로 생성)
		maxDay: '',
		forRerendering: 0,
	}),
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('dangerOccurPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;
		this.searchVM = {
			dangerDtm: '01',
			dangerOccurDtmStat: moment().format('YYYY-MM-DD'),
			dangerOccurDtmEnd: moment().format('YYYY-MM-DD'),
			siteId: this.pageParam.loginUserSiteId,
			cctvId: '',
			warnWorkId: '',
			objectId: '',
			cont: '',
			videoDangerActnDvsnCd: this.pageParam.videoDangerActnDvsnCd,
			actnReasonCont: '',
		};

		const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));

		this.options = {
			siteOptions: this.pageParam.siteOptions,
			cctvOptions: this.pageParam.cctvOptions,
			objectOptions: this.pageParam.objectOptions,
			targetOptions: this.pageParam.targetOptions,
			videoDangerActnDvsnCdOption: [
				{
					grpCd: 'video_danger_actn_dvsn_cd',
					grpCdNm: '영상 위험 조치 구분 코드',
					value: '01',
					text: '위험 작업 발생',
				},
				{
					grpCd: 'video_danger_actn_dvsn_cd',
					grpCdNm: '영상 위험 조치 구분 코드',
					value: '03',
					text: '조치 중',
				},
				{
					grpCd: 'video_danger_actn_dvsn_cd',
					grpCdNm: '영상 위험 조치 구분 코드',
					value: '04',
					text: '위험 해제',
				},
				{
					grpCd: 'video_danger_actn_dvsn_cd',
					grpCdNm: '영상 위험 조치 구분 코드',
					value: '05',
					text: '인식 실패(오탐)',
				},
			],
			dangerDtmOption: commonCodeList.filter(item => item.grpCd == 'danger_dtm'),
		};

		this.gridColumns.filter(item => item.field == 'videoDangerActnDvsnCd')[0].mapData = this.options.videoDangerActnDvsnCdOption;

		axiosExtention = this.$jarvisExtention.axiosExtention;

		this.isDisabledSearchSite = this.pageParam.loginUserRolecd === '00' ? false : true;
	},
	mounted() {
		this.menuGubun = process.env.VUE_APP_MENU_GUBUN;
		this.search();
	},
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			var rule = null;
			if (this.isEditMode) {
				rule = this.detailsValidationRule.edit;
			} else {
				rule = this.detailsValidationRule.create;
			}
			return { detailsItem: rule };
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
		'searchVM.siteId': {
			handler(value, oldValue) {
				if (oldValue !== undefined) {
					this.$axios
						.post(dangerOccurUrl.inqDangerOccurCctvInfo, { siteId: this.searchVM.siteId })
						.then(
							function (r) {
								this.options.cctvOptions = r.data;
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler());
				}
			},
		},
		//조회기간을 최대 x개월 간으로 하기 위해
		'searchVM.dangerOccurDtmStat': {
			handler(value) {
				this.maxDay = moment(value).add(process.env.VUE_APP_MAX_MONTH, 'month').format('YYYY-MM-DD');
			},
		},
		// 팝업 모달이 생겨났을 때, 해당 이미지에 포커스를 두도록(키보드로 이미지를 바로 이동시킬 수 있게 하기 위함)
		isImagePoppedUp() {
			if (this.isImagePoppedUp == true) {
				this.$nextTick(function () {
					let selectedImage = document.getElementsByClassName('active')[0];
					selectedImage.focus();
				});
			}
		},
		// 활성화된 이미지가 변경됐을 때 이미지 데이터를 변경하기 위함
		activeImage() {
			this.popupImg = this.detailsItem.inqDangerOccurFrameImgOutVOs[this.activeImage];
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailFrames');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		// 모범사례 팝업에서 이미지 row를 클릭했을 때 이미지 불러오기
		clickRowImage(index) {
			this.activeImageForExemplaryCase = index;
			// this.$axios.post(exemplaryCaseUrl.imgMain + this.detailsItem.images[index].img, {}).then(response => {
			this.$axios.post(exemplaryCaseUrl.imgMain, { fileName: this.detailsItem.images[index].img }).then(response => {
				this.imageSrc = 'data:image/jpg;base64,' + response.data.imgVO.imgByte;
			});
		},
		// 모범사례 팝업에서 법령row를 클릭했을 때
		clickRowLaw(index) {
			this.activeLaw = index;
			this.detailsItem.lawClss = this.detailsItem.laws[index].lawClss;
			this.detailsItem.stte = this.detailsItem.laws[index].stte;
		},
		//모달창에서 키 다운으로 이전 이미지로 이동
		priviousImage() {
			if (this.activeImage > 0) {
				this.activeImage = this.activeImage - 1;
				this.selectPicture(this.activeImage);
			}
		},
		//모달창에서 키 다운으로 다음 이미지로 이동
		nextImage() {
			if (this.activeImage < this.detailsItem.inqDangerOccurFrameImgOutVOs.length - 1) {
				this.activeImage = this.activeImage + 1;
				this.selectPicture(this.activeImage);
			}
		},
		//모달창에서 이미지를 선택했을 때
		selectPicture(index) {
			if (index < 0 || index >= this.detailsItem.inqDangerOccurFrameImgOutVOs.length) {
				return;
			}
			this.activeImage = index;
			let lastIndexOfFrame = this.detailsItem.inqDangerOccurFrameImgOutVOs.length - 1;
			let endFrameImgSeq = this.detailsItem.inqDangerOccurFrameImgOutVOs[lastIndexOfFrame].frameImgSeq;
			this.$axios
				.post(dangerOccurUrl.inqImgDetailsInfo, {
					siteId: this.detailsItem.siteId,
					cctvId: this.detailsItem.cctvId,
					frameImgSeq: this.detailsItem.inqDangerOccurFrameImgOutVOs[this.activeImage].frameImgSeq,
					endFrameImgSeq: endFrameImgSeq,
				})
				.then(
					function (r) {
						let objects = [];
						// let objects = {};
						if (r.data != '') {
							r.data.forEach(e => {
								objects.push(e);
								// objects[e.objectNm] = e.targetNm;
							});
						}
						this.popupImg.objects = objects;
						this.forRerendering += 1;
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
			// this.pictureNum = index + 1;
			//스크롤바를 내리는 용도
			let distance = (index - 10) * 10;
			$('.modal-content [left]').animate({ scrollTop: index * 130 - 170 + distance }, 50);
		},
		//검색적용
		search() {
			this.searchState = extend(true /*deep*/, {}, this.searchVM);
			this.loadGrid();
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return extend(true /*deep*/, param, this.searchState || {});
		},
		//그리드에서 row를 선택했을 때 detailsItem 세팅용
		setDetailsItem(data, selectedRowItem) {
			this.detailsItemOriginal = $.extend(true, {}, data);
			this.detailsItem = $.extend(true, {}, data);
			this.detailsItem.dangerOccurDtm = selectedRowItem.dangerOccurDtm;
			this.detailsItem.objectId = selectedRowItem.objectId;
			this.detailsItem.targetId = selectedRowItem.targetId;
			this.detailsItem.dangerOccurObjectSeq = selectedRowItem.dangerOccurObjectSeq;
			this.detailsItem.videoDangerActnDvsnCd = selectedRowItem.videoDangerActnDvsnCd;
			this.detailsItem.actnReasonCont = selectedRowItem.actnReasonCont;
			this.detailsItem.strtFrameImgSeq = selectedRowItem.strtFrameImgSeq;
			this.detailsItem.obejctNm = selectedRowItem.obejctNm;
			this.detailsItem.targetNm = selectedRowItem.targetNm;
			this.detailsItem.siteId = selectedRowItem.siteId;
			this.detailsItem.cctvId = selectedRowItem.cctvId;
			this.detailsItem.cctvNmAndInstallPlace = selectedRowItem.cctvNmAndInstallPlace;
			this.detailsItem.slicedDangerOccurFrameImgOutVOs = this.detailsItem.inqDangerOccurFrameImgOutVOs.slice(0, 30);
		},
		//이미지 데이터 세팅
		setAllFramesToImageData() {
			this.detailsItem.inqDangerOccurFrameImgOutVOs.forEach(e => {
				this.$axios.post(dangerOccurUrl.imgOneDangerOccur, { fileName: e.imgSaveFileNm }).then(response => {
					e.imgData = 'data:image/jpg;base64,' + response.data.imgByte;
				});
			});
		},
		// dvsn코드를 통해 dvsnNm 설정
		setActnDvsnCdNmByDvsnCd() {
			this.detailsItem.inqDangerOccurActnOutVOs.forEach(e => {
				this.options.videoDangerActnDvsnCdOption.forEach(e1 => {
					if (e.videoDangerActnDvsnCd === e1.value) {
						e.videoDangerActnDvsnCdNm = e1.text;
						return;
					}
				});
			});
		},
		//그리드 선택 처리
		selectedRowItemChanged(selectedRowItem) {
			this.detailsItem = {};
			this.dangerOccurObjectsAndTargets = {};
			// this.objectAndDangerZoneMap = {};
			this.dangerOccurObjectsAtSequenceMap = {};
			this.dangerZoneAtSequenceMap = {};
			if (!_.isEmpty(selectedRowItem)) {
				this.curDangerDvsnCd = selectedRowItem.videoDangerActnDvsnCd;
				this.$axios
					.post(dangerOccurUrl.inqOneDangerOccur, {
						siteId: selectedRowItem.siteId,
						cctvId: selectedRowItem.cctvId,
						objectId: selectedRowItem.objectId,
						targetId: selectedRowItem.targetId,
						dangerOccurObjectSeq: selectedRowItem.dangerOccurObjectSeq,
						strtFrameImgSeq: selectedRowItem.strtFrameImgSeq,
						endFrameImgSeq: selectedRowItem.endFrameImgSeq,
						dangerOccurDtm: selectedRowItem.dangerOccurDtm,
					})
					.then(
						function (response) {
							//그리드에서 row를 선택했을 때 detailsItem 세팅용
							this.setDetailsItem(response.data, selectedRowItem);
							//해당 위험이력 + 전후 이미지들에서 해당되는 조치대상 및 위험지역 세팅
							this.dangerOccurObjectsAndTargets = response.data.allSeqObjectsAndTargets;
							// this.objectAndDangerZoneMap = response.data.allSeqObjectsAndDangerZoneNms;
							//이미지 데이터 세팅
							this.setAllFramesToImageData();
							// dvsn코드를 통해 dvsnNm 설정
							this.setActnDvsnCdNmByDvsnCd();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
		},
		dangerOccurImgClicked(item, index) {
			this.popupImg = {};
			// img 클릭 시 popup
			this.popupImg = item;
			this.$refs.popupImg.style.display = 'block';
			this.isImagePoppedUp = true;
			this.selectPicture(index);
		},
		popupExemplaryCaseClose() {
			this.$refs.popupExemplaryCase.style.display = 'none';
		},
		popupImgClose() {
			// img popup 닫기
			this.$refs.popupImg.style.display = 'none';
			this.isImagePoppedUp = false;
		},
		// 조치중
		takingAction() {
			if (this.detailsItem.videoDangerActnDvsnCd === '03') {
				alert('이미 조치중 상태입니다.');
			} else {
				this.$axios
					.post(dangerOccurUrl.dangerOccurTakingAction, this.detailsItem)
					.then(
						function () {
							this.loadGrid();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		// 오인식
		misdetection() {
			if (this.detailsItem.videoDangerActnDvsnCd === '05') {
				alert('이미 오인식 처리되었습니다.');
			} else {
				this.$axios
					.post(dangerOccurUrl.dangerOccurMisdetection, this.detailsItem)
					.then(
						function () {
							this.loadGrid();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		// 오인식 취소
		deMisdetection() {
			if (this.detailsItem.videoDangerActnDvsnCd !== '05') {
				alert('오인식 상태가 아닙니다.');
			} else {
				this.$axios
					.post(dangerOccurUrl.dangerOccurDeMisdetection, this.detailsItem)
					.then(
						function () {
							this.loadGrid();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		popupExemplaryCase() {
			this.$axios
				.post(exemplaryCaseUrl.inqOneExemplaryCase, { objectId: this.detailsItem.objectId, targetId: this.detailsItem.targetId })
				.then(
					function (r) {
						this.detailsItem = $.extend(true /*deep*/, this.detailsItem, r.data);
						if (r.data.images.length) {
							this.activeImageForExemplaryCase = 0;
							this.imageSrc = 'data:image/jpg;base64, ' + r.data.firstImgVO.imgByte;
						} else {
							this.imageSrc = '/images/noimage.png';
						}

						if (r.data.laws.length != 0) {
							this.activeLaw = 0;
							this.detailsItem.lawClss = r.data.laws[0].lawClss;
							this.detailsItem.stte = r.data.laws[0].stte;
						}
						this.detailsItem;
						this.$refs.popupExemplaryCase.style.display = 'block';
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
	},
};
$(function () {
	$('#ex-caseBtn').click(function () {
		$('.exCase').show();
		$('#law-panel').hide();
		$('#ex-caseBtn').css({
			backgroundColor: 'white',
			borderBottom: '1px solid white',
			color: '#333333',
		});
		$('#lawBtn').css({
			backgroundColor: '#ebebeb',
			borderBottom: '1px solid #ddd',
			color: '#787878',
		});
	});
	$('#lawBtn').click(function () {
		$('#law-panel').show();
		$('.exCase').hide();
		$('#ex-caseBtn').css({
			backgroundColor: '#ebebeb',
			borderBottom: '1px solid #ddd',
			color: '#787878',
		});
		$('#lawBtn').css({
			backgroundColor: 'white',
			borderBottom: '1px solid white',
			color: '#333333',
		});
	});
});
</script>

<style>
*:focus {
	outline: none;
}
.modal-content img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.modal-content [photo] {
	cursor: pointer;
	border-radius: 4px;
	overflow: hidden;
}
.modal-content [photo] img {
	min-height: 120px;
	height: 120px;
}
.modal-content [photo].active {
	border: 5px solid #1878f3;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
}

.modal-content [left] [photo] {
	margin-top: 20px;
}

[v-cloak] > * {
	display: none !important;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	width: 60%;
	margin-top: 5px;
	max-width: 130px;
}
@media (min-width: 500px) and (max-width: 1800px) {
	.modal-content {
		background-color: #fefefe;
		margin: 1% auto;
		padding: 20px;
		border: 1px solid #888;
		width: 80%;
	}
	.modal-content [left] {
		padding: 0 15px;
		height: 85vh !important;
	}
	#popupImg {
		width: 55vw !important;
		max-height: 55vh !important;
	}
}

@media (min-width: 1801px) {
	.modal-content {
		background-color: #fefefe;
		margin: 4% auto;
		padding: 20px;
		border: 1px solid #888;
		width: 1300px;
		height: 800px;
	}
	.modal-content [left] {
		padding: 0 15px;
	}
}

/* The Modal (background) */
.modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 99; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	background-color: rgb(0, 0, 0); /*Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* //Black w/ opacity */
}

/* The Close Button */
.close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}
.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}
label {
	text-align: right;
	margin-right: 12px;
}
.panel-heading {
	padding-left: 20px !important;
}
.border {
	border-radius: 3px;
	border: 1px solid #ddd;
	float: left;
}
.border-bottom {
	width: 100%;
	margin: auto;
	border-bottom: 1px solid #ddd !important;
}
.objectBox {
	/* height: 135px; */
	min-height: 100px;
	/* height: auto; */
}
.rhtBox {
	/* height: 135px; */
	min-height: 135px;
	height: auto;
}
.rhtBox > div > h3 {
	/* background: #f9f9f9; */
	background: #e0e0e0;
}
.rhtBox .row {
	border-bottom: 1px solid #ddd !important;
}
h3 {
	font-weight: bold !important;
	margin: 0 !important;
	padding: 10px 10px 10px 15px;
}
.mBox {
	/* height: 135px; */
	height: 170px;
	border-radius: 3px;
	border: 1px solid #ddd;
	/* margin-bottom: 50px; */
	margin-bottom: 30px;
	margin-top: 30px;
}
.mBox > div:first-child {
	background: #f9f9f9;
}

/* .mBox > div:first-child {
	background: #e0e0e0;
} */
.mBox > div > h4 {
	background: #e0e0e0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
.popupEx .law-panel .panel-body textarea {
	height: 300px;
	resize: none;
	margin-bottom: 20px;
}
.popupEx > div > .panel-heading {
	padding: 0;
	border: none;
	position: relative;
	z-index: 99;
	font-size: 15px;
	font-weight: bold;
}
.popupEx > div > .panel-body {
	padding: 0;
	margin: 0;
	position: relative;
	top: -1px;
}
#law-panel > .panel-body {
	padding: 20px;
	margin: 0;
	height: 640px;
}
#law-panel > .panel-body > .row {
	margin-top: auto;
}
.exCase {
	margin: 0;
	padding: 20px;
}
.popupEx > div > .panel-body > .law-panel {
	display: none;
	margin: 0;
	overflow-y: auto;
}
#ex-caseBtn,
#lawBtn {
	width: 90px;
	height: 35px;
	border: 1px solid #ddd;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
#ex-caseBtn {
	background-color: white;
	border-bottom: 1px solid white;
	color: #333333;
}
#lawBtn {
	background-color: #ebebeb;
	border-bottom: 1px solid #ddd;
	color: #787878;
}

.plusEx > .panel > .panel-heading > div {
	display: flex;
}
.plusEx > .panel > .panel-heading > div > div {
	display: flex;
	align-items: center;
	margin-right: 40px;
}
.plusEx > .panel > .panel-heading > div > div > * {
	margin-right: 5px;
}
.plusEx > .panel > .panel-heading span {
	margin-right: 10px;
	min-width: 60px;
}

.plusEx > .panel > .panel-body > div {
	width: 690px;
	height: 568px;
	float: left;
	margin: 0;
	flex-direction: column;
}
.plusEx > .panel > .panel-body > div:last-child {
	float: right;
}

.plusEx .panel-heading {
	font-weight: bold;
}
.plusEx .panel-heading input {
	max-width: 160px;
}

.plusEx .law-panel .panel-body textarea {
	height: 222px;
	resize: none;
	margin-bottom: 15px;
}
.plusEx .law-panel .btn-wrapper {
	padding: 0;
	margin-bottom: 15px;
}
.plusEx .law-panel .btn-wrapper .btn {
	width: calc(50% - 5px);
	margin: 0;
}
.plusEx .law-panel .btn-wrapper .btn:last-child {
	float: right;
}
.plusEx tr.active td {
	background: #ddd;
}

.plusEx .panel-body .panel-flat .panel-body {
	display: flex;
	flex-direction: column;
}

.plusEx .table-wrapper {
	max-height: 200px;
	overflow-y: auto;
}

.modal .table-wrapper {
	max-height: 300px;
	overflow-y: auto;
}
.modal .table tbody tr:hover {
	cursor: pointer;
}

.table th {
	background: #eee;
	vertical-align: middle !important;
	font-weight: bold;
	text-align: center;
}
.panel-body .table th:first-child {
	width: 120px;
	text-align: center;
}

.table td {
	vertical-align: middle;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 1px;
	text-align: left !important;
}
.panel-body .table td:first-child {
	text-align: center;
}
.panel-body .table td:last-child {
	text-align: center;
	width: 80px;
}

.table [empty] {
	display: none;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}

.btn-wrapper {
	width: 100%;
	clear: both;
	padding-bottom: 20px;
	padding-right: 20px;
}
.btn-wrapper .btn {
	margin-left: 10px;
}

.modal .search {
	text-align: center;
	margin-bottom: 15px;
}
.modal-header {
	padding: 0;
}
.close {
	right: 0 !important;
	top: 0 !important;
	font-size: 35px;
	margin-top: -15px !important;
	margin-right: -5px;
}
.modal-body {
	padding: 0;
	padding-top: 20px;
}
[type='search'] {
	display: inline-block;
	width: 330px;
	margin-right: 5px;
}
.modal-dialog > .modal-content {
	width: 800px;
	margin: 15% auto;
}
</style>
