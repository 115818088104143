var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "rootVM" } }, [
    _vm._m(0),
    _c("div", { staticClass: "panel panel-flat" }, [
      _c(
        "div",
        {
          staticClass: "panel-body",
          staticStyle: { margin: "20px auto 0px 130px" }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.search.apply(null, arguments)
                },
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.search.apply(null, arguments)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-md-4",
                    staticStyle: { width: "460px", "margin-left": "-20px" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("select2", {
                          attrs: { options: _vm.options.dangerDtmOption },
                          model: {
                            value: _vm.searchVM.dangerDtm,
                            callback: function($$v) {
                              _vm.$set(_vm.searchVM, "dangerDtm", $$v)
                            },
                            expression: "searchVM.dangerDtm"
                          }
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchVM.dangerOccurDtmStat,
                              expression: "searchVM.dangerOccurDtmStat"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "date" },
                          domProps: { value: _vm.searchVM.dangerOccurDtmStat },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.searchVM,
                                "dangerOccurDtmStat",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v("  ~  "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchVM.dangerOccurDtmEnd,
                              expression: "searchVM.dangerOccurDtmEnd"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "date",
                            min: _vm.searchVM.dangerOccurDtmStat,
                            max: _vm.maxDay
                          },
                          domProps: { value: _vm.searchVM.dangerOccurDtmEnd },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.searchVM,
                                "dangerOccurDtmEnd",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-md-2",
                    staticStyle: { width: "265px", "margin-left": "-30px" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticStyle: { width: "90px" } }, [
                          _vm._v(_vm._s(_vm.detailsFieldMap.searchSite))
                        ]),
                        _c(
                          "select2",
                          {
                            attrs: { disabled: _vm.isDisabledSearchSite },
                            model: {
                              value: _vm.searchVM.siteId,
                              callback: function($$v) {
                                _vm.$set(_vm.searchVM, "siteId", $$v)
                              },
                              expression: "searchVM.siteId"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("전체")
                            ]),
                            _vm._l(_vm.options.siteOptions, function(item) {
                              return _c(
                                "option",
                                {
                                  key: item.siteId,
                                  domProps: { value: item.siteId }
                                },
                                [_vm._v(" " + _vm._s(item.siteNm) + " ")]
                              )
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-md-2",
                    staticStyle: { width: "250px", "margin-left": "10px" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticStyle: { width: "73px" } }, [
                          _vm._v(_vm._s(_vm.detailsFieldMap.searchCctv))
                        ]),
                        _c(
                          "select2",
                          {
                            model: {
                              value: _vm.searchVM.cctvId,
                              callback: function($$v) {
                                _vm.$set(_vm.searchVM, "cctvId", $$v)
                              },
                              expression: "searchVM.cctvId"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("전체")
                            ]),
                            _vm._l(_vm.options.cctvOptions, function(item) {
                              return _c(
                                "option",
                                {
                                  key: item.cctvId,
                                  domProps: { value: item.cctvId }
                                },
                                [_vm._v(" " + _vm._s(item.cctvNm) + " ")]
                              )
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-md-2",
                    staticStyle: { width: "250px", "margin-left": "-48px" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [
                          _vm._v(
                            _vm._s(
                              _vm.detailsFieldMap.searchVideoDangerActnDvsnCd
                            )
                          )
                        ]),
                        _c(
                          "select2",
                          {
                            model: {
                              value: _vm.searchVM.videoDangerActnDvsnCd,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.searchVM,
                                  "videoDangerActnDvsnCd",
                                  $$v
                                )
                              },
                              expression: "searchVM.videoDangerActnDvsnCd"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("전체")
                            ]),
                            _vm._l(
                              _vm.options.videoDangerActnDvsnCdOption,
                              function(item) {
                                return _c(
                                  "option",
                                  {
                                    key: item.value,
                                    domProps: { value: item.value }
                                  },
                                  [_vm._v(" " + _vm._s(item.text) + " ")]
                                )
                              }
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3", staticStyle: { width: "350px" } },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [
                          _vm._v(_vm._s(_vm.detailsFieldMap.searchObject))
                        ]),
                        _c(
                          "select2",
                          {
                            model: {
                              value: _vm.searchVM.objectId,
                              callback: function($$v) {
                                _vm.$set(_vm.searchVM, "objectId", $$v)
                              },
                              expression: "searchVM.objectId"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("전체")
                            ]),
                            _vm._l(_vm.options.objectOptions, function(item) {
                              return _c(
                                "option",
                                {
                                  key: item.objectId,
                                  domProps: { value: item.objectId }
                                },
                                [_vm._v(" " + _vm._s(item.objectNm) + " ")]
                              )
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-md-3",
                    staticStyle: { width: "320px", "margin-left": "63px" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [
                          _vm._v(_vm._s(_vm.detailsFieldMap.searchTarget))
                        ]),
                        _c(
                          "select2",
                          {
                            model: {
                              value: _vm.searchVM.targetId,
                              callback: function($$v) {
                                _vm.$set(_vm.searchVM, "targetId", $$v)
                              },
                              expression: "searchVM.targetId"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("전체")
                            ]),
                            _vm._l(_vm.options.targetOptions, function(item) {
                              return _c(
                                "option",
                                {
                                  key: item.targetId,
                                  domProps: { value: item.targetId }
                                },
                                [_vm._v(" " + _vm._s(item.targetNm) + " ")]
                              )
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                ),
                _vm._m(1)
              ])
            ]
          )
        ]
      )
    ]),
    _c(
      "div",
      [
        _c("KendoGrid", {
          ref: "grid",
          attrs: {
            "auto-bind": false,
            "api-url": _vm.apiUrl.pageListApi,
            columns: _vm.gridColumns,
            "apply-search-condition": _vm.applySearchStateOnGridLoad
          },
          on: { "selected-row-item-changed": _vm.selectedRowItemChanged }
        })
      ],
      1
    ),
    _c("div", { staticClass: "mt-10 mb-15 text-right" }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isEditMode,
            expression: "isEditMode"
          }
        ],
        staticClass: "form-horizontal form-validate-jquery",
        attrs: { id: "detailFrames" }
      },
      [
        _c("div", { staticClass: "panel panel-flat" }, [
          _c("div", { staticClass: "panel-heading" }, [
            _c("h6", { staticClass: "panel-title" }, [
              _c("b", [
                _vm._v(
                  "프레임 정보 (" +
                    _vm._s(_vm.detailsItem.cctvNmAndInstallPlace) +
                    ")"
                )
              ])
            ]),
            _c("div", { staticClass: "heading-elements" }, [
              _c("ul", { staticClass: "icons-list" }, [
                _c("li", [
                  _c("a", {
                    attrs: { "data-action": "collapse" },
                    on: { click: _vm.pannelHidden }
                  })
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "panel-body" }, [
            _c(
              "div",
              {
                staticClass: "border",
                staticStyle: {
                  width: "1020px",
                  height: "auto",
                  "max-height": "800px",
                  "overflow-y": "auto"
                }
              },
              _vm._l(_vm.detailsItem.slicedDangerOccurFrameImgOutVOs, function(
                item,
                index
              ) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticStyle: {
                      display: "inline-block",
                      "text-align": "center"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "200px",
                        height: "112.5px",
                        "object-fit": "contain",
                        padding: "5px",
                        display: "block",
                        cursor: "pointer"
                      },
                      attrs: { src: item.imgData },
                      on: {
                        click: function($event) {
                          return _vm.dangerOccurImgClicked(item, index)
                        }
                      }
                    }),
                    _c("span", [_vm._v(_vm._s(item.imgSaveDtm))])
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              {
                staticClass: "border",
                staticStyle: { width: "358px", margin: "0 0 20px 20px" }
              },
              [
                _c(
                  "div",
                  { staticClass: "rhtBox", staticStyle: { height: "auto" } },
                  [
                    _vm._m(2),
                    _vm._l(_vm.dangerOccurObjectsAndTargets, function(
                      targets,
                      object
                    ) {
                      return _c(
                        "div",
                        {
                          key: object,
                          staticClass: "row",
                          staticStyle: {
                            width: "100%",
                            "min-height": "135px",
                            margin: "0px"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "17px",
                                "margin-bottom": "10px",
                                "padding-left": "15px",
                                "margin-top": "15px",
                                "margin-left": "20px"
                              }
                            },
                            [_vm._v(_vm._s(object))]
                          ),
                          _vm._l(targets, function(target) {
                            return _c(
                              "li",
                              {
                                key: target,
                                staticStyle: { "margin-left": "20px" }
                              },
                              [_vm._v(" " + _vm._s(target) + " ")]
                            )
                          })
                        ],
                        2
                      )
                    })
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "rhtBox" },
                  [
                    _vm._m(3),
                    _vm._l(_vm.detailsItem.inqDangerOccurActnOutVOs, function(
                      item,
                      index
                    ) {
                      return _c("div", { key: index }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "15px",
                              "margin-bottom": "15px",
                              "margin-left": "20px",
                              "padding-left": "15px"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(item.actnDtm) +
                                " : " +
                                _vm._s(item.videoDangerActnDvsnCdNm) +
                                " "
                            )
                          ]
                        )
                      ])
                    })
                  ],
                  2
                ),
                _c("div", {
                  staticClass: "border-bottom",
                  staticStyle: { "margin-bottom": "20px" }
                }),
                _vm.curDangerDvsnCd != "04"
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          height: "auto",
                          display: "flex",
                          padding: "10px"
                        }
                      },
                      [
                        _vm._m(4),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.detailsItem.takingActionMin,
                              expression: "detailsItem.takingActionMin"
                            }
                          ],
                          staticClass: "form-control",
                          staticStyle: {
                            display: "inline",
                            width: "50px",
                            "text-align": "center"
                          },
                          attrs: { type: "number" },
                          domProps: { value: _vm.detailsItem.takingActionMin },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.detailsItem,
                                "takingActionMin",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._m(5),
                        _c(
                          "button",
                          {
                            staticClass: "btn bg-primary",
                            staticStyle: { "margin-left": "10px" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.takingAction.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("조치중")]
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticStyle: { display: "flex", margin: "10px 0px" } },
                  [
                    _vm.detailsItem.videoDangerActnDvsnCd != "04"
                      ? _c("div", [
                          _vm.detailsItem.videoDangerActnDvsnCd != "05"
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn bg-primary",
                                  staticStyle: { flex: "1", margin: "0px 5px" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.misdetection.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" 오인식 ")]
                              )
                            : _vm._e(),
                          _vm.detailsItem.videoDangerActnDvsnCd == "05"
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn bg-primary",
                                  staticStyle: { flex: "1", margin: "0px 5px" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.deMisdetection.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" 오인식 취소 ")]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm.menuGubun != "01"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn bg-primary",
                            staticStyle: { flex: "1", margin: "0px 5px" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.popupExemplaryCase.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [_vm._v(" 모범사례 ")]
                        )
                      : _vm._e()
                  ]
                )
              ]
            )
          ])
        ])
      ]
    ),
    _c("div", { ref: "popupImg", staticClass: "modal" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("span", { staticClass: "close", on: { click: _vm.popupImgClose } }, [
          _vm._v("×")
        ]),
        _c("div", { staticStyle: { height: "30px" } }),
        _c(
          "div",
          {
            staticClass: "row",
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "center"
            }
          },
          [
            _c("div", { staticClass: "col-md-3" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    flex: "1",
                    "overflow-y": "auto",
                    "flex-direction": "column",
                    height: "720px",
                    "max-height": "720px",
                    "border-right": "1px solid #ddd",
                    "padding-right": "15px"
                  },
                  attrs: { left: "" }
                },
                _vm._l(_vm.detailsItem.inqDangerOccurFrameImgOutVOs, function(
                  item,
                  index
                ) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: { active: index == _vm.activeImage },
                      staticStyle: { position: "relative", overflow: "hidden" },
                      attrs: { tabindex: index, photo: "" },
                      on: {
                        click: function($event) {
                          return _vm.selectPicture(index)
                        },
                        keydown: [
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp"
                              ])
                            ) {
                              return null
                            }
                            return _vm.priviousImage()
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "left", 37, $event.key, [
                                "Left",
                                "ArrowLeft"
                              ])
                            ) {
                              return null
                            }
                            if ("button" in $event && $event.button !== 0) {
                              return null
                            }
                            return _vm.priviousImage()
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown"
                              ])
                            ) {
                              return null
                            }
                            return _vm.nextImage()
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "right", 39, $event.key, [
                                "Right",
                                "ArrowRight"
                              ])
                            ) {
                              return null
                            }
                            if ("button" in $event && $event.button !== 2) {
                              return null
                            }
                            return _vm.nextImage()
                          }
                        ]
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.detailsItem.inqDangerOccurFrameImgOutVOs[index]
                              .imgData
                        }
                      }),
                      _vm._m(6, true)
                    ]
                  )
                }),
                0
              )
            ]),
            _c("div", { staticClass: "col-md-9", attrs: { align: "center" } }, [
              _c(
                "div",
                {
                  staticStyle: {
                    flex: "5 1 0%",
                    height: "inherit",
                    "justify-content": "center"
                  },
                  attrs: { align: "center", right: "" }
                },
                [
                  _c("img", {
                    staticStyle: {
                      width: "910px",
                      "max-height": "520px",
                      "object-fit": "contain",
                      display: "block"
                    },
                    attrs: { id: "popupImg", src: this.popupImg.imgData }
                  })
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    flex: "5 1 0%",
                    "margin-left": "15px",
                    "justify-content": "center"
                  },
                  attrs: { align: "center", right: "" }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "mBox col-md-3" }, [
                      _vm._m(7),
                      _c("div", { staticClass: "border-bottom" }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "10px",
                            "margin-left": "20px"
                          }
                        },
                        [_vm._v(_vm._s(_vm.detailsItem.cctvNmAndInstallPlace))]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "10px",
                            "margin-left": "20px"
                          }
                        },
                        [_vm._v(_vm._s(_vm.popupImg.imgSaveDtm))]
                      )
                    ]),
                    _c("div", { staticClass: "mBox" }, [
                      _vm._m(8),
                      _c("div", { staticClass: "border-bottom" }),
                      _c(
                        "div",
                        {
                          key: _vm.forRerendering,
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center"
                          }
                        },
                        _vm._l(_vm.popupImg.objects, function(element, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticStyle: {
                                display: "inline-block",
                                "max-width": "50%",
                                margin: "10px"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "17px",
                                    "font-weight": "bold",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v(" " + _vm._s(element.objectNm) + " ")]
                              ),
                              _vm._l(element.targetNms, function(targetNm, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticStyle: {
                                      "font-size": "14px",
                                      "text-align": "center"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(targetNm) + " ")]
                                )
                              })
                            ],
                            2
                          )
                        }),
                        0
                      )
                    ])
                  ])
                ]
              )
            ])
          ]
        )
      ])
    ]),
    _c("div", { ref: "popupExemplaryCase", staticClass: "modal" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c(
          "div",
          {
            staticClass: "form-horizontal form-validate-jquery popupEx",
            attrs: { action: "#" }
          },
          [
            _c("div", [
              _c("div", { staticClass: "panel-heading" }, [
                _c("input", {
                  attrs: { type: "button", id: "ex-caseBtn", value: "사진" }
                }),
                _c("input", {
                  attrs: { type: "button", id: "lawBtn", value: "관련법령" }
                }),
                _c(
                  "span",
                  {
                    staticClass: "close",
                    on: { click: _vm.popupExemplaryCaseClose }
                  },
                  [_vm._v("×")]
                )
              ]),
              _c("div", { staticClass: "panel-body" }, [
                _c("div", { staticClass: "panel panel-flat exCase row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-9", attrs: { align: "center" } },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "100%",
                          height: "600px",
                          "object-fit": "contain"
                        },
                        attrs: { src: _vm.imageSrc }
                      })
                    ]
                  ),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "div",
                      {
                        ref: "tableWrapperImage",
                        staticClass: "table-wrapper"
                      },
                      [
                        _c("table", { staticClass: "table" }, [
                          _vm._m(9),
                          _c(
                            "tbody",
                            [
                              _vm._l(_vm.detailsItem.images, function(
                                row,
                                index
                              ) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    class: {
                                      active:
                                        _vm.activeImageForExemplaryCase == index
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickRowImage(index)
                                      }
                                    }
                                  },
                                  [
                                    _c("td", [
                                      _vm._v(_vm._s(row.regDtm.slice(0, 10)))
                                    ]),
                                    _c("td", { attrs: { colspan: "2" } }, [
                                      _vm._v(_vm._s(row.imgOriNm))
                                    ])
                                  ]
                                )
                              }),
                              _vm._m(10)
                            ],
                            2
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticClass: "panel panel-flat law-panel",
                    attrs: { id: "law-panel" }
                  },
                  [
                    _c("div", { staticClass: "panel-body" }, [
                      _c(
                        "div",
                        {
                          staticClass: "row",
                          staticStyle: { "padding-left": "10px" }
                        },
                        [
                          _c("div", { staticClass: "col-md-9" }, [
                            _c("div", [
                              _c(
                                "label",
                                {
                                  staticStyle: {
                                    width: "70px",
                                    "font-size": "18px"
                                  }
                                },
                                [_vm._v("법조항")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.detailsItem.lawClss,
                                    expression: "detailsItem.lawClss"
                                  }
                                ],
                                staticClass: "form-control",
                                staticStyle: { "margin-bottom": "15px" },
                                attrs: { type: "text", disabled: true },
                                domProps: { value: _vm.detailsItem.lawClss },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.detailsItem,
                                      "lawClss",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c(
                              "label",
                              { staticStyle: { "font-size": "18px" } },
                              [_vm._v("법령")]
                            ),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.detailsItem.stte,
                                  expression: "detailsItem.stte"
                                }
                              ],
                              staticClass: "form-control",
                              staticStyle: { "margin-bottom": "15px" },
                              attrs: { disabled: "true" },
                              domProps: { value: _vm.detailsItem.stte },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.detailsItem,
                                    "stte",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c(
                              "div",
                              {
                                ref: "tableWrapperLaw",
                                staticClass: "table-wrapper"
                              },
                              [
                                _c("table", { staticClass: "table" }, [
                                  _vm._m(11),
                                  _c(
                                    "tbody",
                                    [
                                      _vm._l(_vm.detailsItem.laws, function(
                                        row,
                                        index
                                      ) {
                                        return _c(
                                          "tr",
                                          {
                                            key: index,
                                            class: {
                                              active: _vm.activeLaw == index
                                            },
                                            attrs: { id: "laws_" + index },
                                            on: {
                                              click: function($event) {
                                                return _vm.clickRowLaw(index)
                                              }
                                            }
                                          },
                                          [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(row.regDtm.slice(0, 10))
                                              )
                                            ]),
                                            _c(
                                              "td",
                                              {
                                                attrs: {
                                                  colspan: "2",
                                                  align: "left"
                                                }
                                              },
                                              [_vm._v(_vm._s(row.lawClss))]
                                            )
                                          ]
                                        )
                                      }),
                                      _vm._m(12)
                                    ],
                                    2
                                  )
                                ])
                              ]
                            )
                          ])
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "pageTitle" } }, [
      _c("b", [_vm._v("위험발생이력")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mb-10 text-right", staticStyle: { float: "right" } },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-labeled bg-primary",
            attrs: { type: "submit" }
          },
          [
            _c("b", [_c("i", { staticClass: "icon-search4" })]),
            _vm._v(" 조회 ")
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "inline-block", width: "100%" } },
      [_c("h3", [_vm._v("조치 대상")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h3", [_vm._v("조치 이력")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          background: "none",
          "flex-grow": "2",
          margin: "auto",
          "text-align": "center"
        }
      },
      [
        _c("label", { staticStyle: { margin: "0px auto" } }, [
          _vm._v("조치요청을 하시겠습니까?")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          background: "none",
          margin: "auto",
          "text-align": "center",
          "margin-left": "5px"
        }
      },
      [_c("label", { staticStyle: { margin: "0px auto" } }, [_vm._v("분")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticStyle: {
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h4", [_vm._v("장소 및 시각")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h4", [_vm._v("조치 대상")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [_c("th", [_vm._v("일자")]), _c("th", [_vm._v("파일명")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { attrs: { empty: "" } }, [
      _c("td", { attrs: { colspan: "3" } }, [
        _vm._v("추가된 이미지가 없습니다")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { attrs: { algin: "center" } }, [
        _c("th", [_vm._v("일자")]),
        _c("th", [_vm._v("법조항")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { attrs: { empty: "" } }, [
      _c("td", { attrs: { colspan: "3" } }, [_vm._v("추가된 법령이 없습니다")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }